import './App.css';
import Particles from 'react-particles-js';
import Typing from 'react-typing-animation';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <Particles params={{
            		particles: {
            			line_linked: {
            				shadow: {
            					enable: true,
            					color: "#3CA9D1",
            					blur: 5
            				}
            			}
            		}
            	}}/>
        <div className="typing">
          <h1>Nuvitol Pharmaceuticals</h1>
          <Typing>
            <h2>We are on our way.</h2>
          </Typing>
        </div>
          <Typing>
            <span style={{color: '#fff', fontStyle: 'italic'}}>Something Big is Coming Your Way</span> 
          </Typing>
      </header>
    </div>
  );
}

export default App;
